import { ellipsis } from "polished";
import React, { useContext } from "react";
import styled, { css } from "styled-components";

import Dropdown from "~/components/menus/Dropdown";
import BaseDropdownButton from "~/components/menus/DropdownButton";
import DropdownHeader from "~/components/menus/DropdownHeader";
import SelectMenu from "~/components/menus/SelectMenu";
import SelectMenuOption from "~/components/menus/SelectMenuOption";
import {
  bgColor,
  border,
  borderRadius,
  fgColor,
  focusOutline,
} from "~/styles/mixins";

import {
  ColumnSelectContext,
  defaultColumns,
} from "../context/ColumnSelectContext";

const columnDisplayMap: { [key: string]: string } = {
  number: "Number",
  status: "Status",
  category: "Category",
  project: "Project",
  question: "Question",
  answer: "Answer",
  createdAt: "Created At",
  modifiedAt: "Updated At",
};

const ColumnSelect = () => {
  const { selectedColumns, toggleColumn } = useContext(ColumnSelectContext);

  return (
    <DropdownButton
      hasSelection={false}
      dropdown={
        <Dropdown>
          <DropdownHeader title="Select Display Columns" />
          <MenuScroller>
            <SelectMenu
              isSelected={(column) =>
                selectedColumns[column as keyof typeof defaultColumns]
              }
              onSelect={(column) =>
                toggleColumn(column as keyof typeof defaultColumns)
              }
              onDeselect={(column) =>
                toggleColumn(column as keyof typeof defaultColumns)
              }
              aria-multiselectable="true"
            >
              {Object.keys(defaultColumns).map((column) => (
                <SelectMenuOption key={column} value={column} label={column}>
                  {columnDisplayMap[column]}
                </SelectMenuOption>
              ))}
            </SelectMenu>
          </MenuScroller>
        </Dropdown>
      }
    >
      Columns {String.fromCharCode(9662)}
    </DropdownButton>
  );
};

const DropdownButton = styled(BaseDropdownButton).withConfig<{
  hasSelection: boolean;
}>({
  shouldForwardProp: (prop) => prop !== "hasSelection",
})`
  ${borderRadius()}
  ${focusOutline()};
  ${bgColor.transparent()};
  ${border.transparent()};
  ${ellipsis()};
  height: 1.75rem;

  &:hover {
    ${bgColor.gray300()};
  }

  &[aria-expanded="true"] {
    ${bgColor.primary()};
    ${fgColor.white()};
  }

  ${(props) =>
    props.hasSelection &&
    css`
      ${bgColor.white()};
      ${fgColor.primary()};
      ${border.primary()};
    `}
`;

const MenuScroller = styled.div`
  min-width: 20rem;
  max-height: 20rem;
  overflow-y: scroll;
`;

export default ColumnSelect;
