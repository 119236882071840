import { createContext } from "react";

export const defaultColumns = {
  number: true,
  status: true,
  category: true,
  project: true,
  question: true,
  answer: true,
  createdAt: false,
  modifiedAt: false,
};

interface ColumnSelectContextType {
  selectedColumns: typeof defaultColumns;
  toggleColumn: (column: keyof typeof defaultColumns) => void;
}

export const ColumnSelectContext = createContext<ColumnSelectContextType>({
  selectedColumns: defaultColumns,
  toggleColumn: () => {},
});
