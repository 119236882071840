import { gql } from "@apollo/client";
import { differenceInDays, max } from "date-fns";
import { flatten, orderBy } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";

import ColumnSelect from "~/components/QAndA/components/ColumnSelect";
import { NewQuestionLogDialog } from "~/components/QAndA/components/NewQuestionLogDialog";
import PortfolioFilter from "~/components/QAndA/components/PortfolioFilter";
import QuestionLogDropdown from "~/components/QAndA/components/QuestionLogDropdown";
import { QuestionLogMenu } from "~/components/QAndA/components/QuestionLogMenu";
import { UpdateQuestionLogDialog } from "~/components/QAndA/components/UpdateQuestionLogDialog";
import {
  ColumnSelectContext,
  defaultColumns,
} from "~/components/QAndA/context/ColumnSelectContext";
import { QuestionPortfoliosProvider } from "~/components/QAndA/context/QuestionPortfoliosContext";
import { QAndASubjectFragment } from "~/components/QAndA/QAndA.generated";
import { useQuestionLogRouting } from "~/components/QAndA/useQuestionLogRouting";
import { bgColor, border, borderRadius, centerContent } from "~/styles/mixins";

import { useQuestionsLazyQuery } from "./api/queries.generated";
import CategoryFilter from "./components/CategoryFilter";
import LastEdit from "./components/LastEdit";
import NewQuestionButton from "./components/NewQuestionButton";
import ProjectFilter from "./components/ProjectFilter";
import QuestionsGrid from "./components/QuestionsGrid";
import StatusFilter, { Status } from "./components/StatusFilter";
import CategoryFilterContext, {
  useCategoryFilter,
} from "./context/CategoryFilterContext";
import OrderingContext, {
  Field,
  useOrderingContext,
} from "./context/OrderingContext";
import PortfolioFilterContext, {
  usePortfolioFilter,
} from "./context/PortfolioFilterContext";
import ProjectFilterContext, {
  useProjectFilter,
} from "./context/ProjectFilterContext";
import QAndAContext, { QAndAContextValue } from "./context/QAndAContext";
import { QuestionCategoriesProvider } from "./context/QuestionCategoriesContext";
import { ChildQuestion, ParentQuestion } from "./context/QuestionContext";
import { QuestionProjectsProvider } from "./context/QuestionProjectsContext";

export const Q_AND_A_SUBJECT_FRAGMENT = gql`
  fragment QAndASubject on FundEntity {
    __typename
    id
    org {
      id
    }
    userGroups {
      id
      name
    }
    qAndALogs {
      id
      name
      restricted
      groups {
        id
      }
    }
  }
`;

export interface QAndAProps {
  subject: QAndASubjectFragment;
  onView?: () => void;
  isQAndAAdmin: boolean;
  canEditQuestions: boolean;
  canEditQuestionAnswers: boolean;
}

const QAndA = ({
  subject,
  isQAndAAdmin,
  canEditQuestions,
  canEditQuestionAnswers,
  onView = () => {},
}: QAndAProps) => {
  useEffect(() => onView(), [onView]);
  const [status, setStatus] = useState<Status>("open");
  const routing = useQuestionLogRouting(subject);
  const [getQuestions, questionsQuery] = useQuestionsLazyQuery();

  useEffect(() => {
    if (routing.selectedLog?.id)
      getQuestions({
        variables: { logId: routing.selectedLog.id },
      });
  }, [routing.selectedLog?.id, getQuestions]);

  const [savingCount, setSavingCount] = useState(0);
  const [addLog, setAddLog] = useState(false);
  const [editLog, setEditLog] = useState(false);
  const incSavingCount = useCallback(() => setSavingCount((c) => c + 1), []);
  const decSavingCount = useCallback(() => setSavingCount((c) => c - 1), []);
  const isSaving = savingCount > 0;

  const gridRef = useRef<HTMLTableElement>(null);
  const focusQuestion: QAndAContextValue["focusQuestion"] = useCallback(
    (id) => {
      const row = gridRef.current?.querySelector(`[data-question-id="${id}"]`);
      if (!row) return;

      row.scrollIntoView();
      (row.querySelector('[contenteditable="true"]') as HTMLElement)?.focus();
    },
    []
  );

  const context = useMemo(
    () => ({ incSavingCount, decSavingCount, focusQuestion }),
    [incSavingCount, decSavingCount, focusQuestion]
  );

  const ordering = useOrderingContext();
  const categoryFilter = useCategoryFilter(routing.selectedLog?.id || null);
  const projectFilter = useProjectFilter(subject);
  const portfolioFilter = usePortfolioFilter(subject);

  const topLevelQuestions = useMemo(
    () => questionsQuery.data?.questions ?? [],
    [questionsQuery.data]
  );

  const allQuestions = useMemo(
    () =>
      flatten(
        topLevelQuestions.map((question) => [question, ...question.followUps])
      ),
    [topLevelQuestions]
  );

  const now = useMemo(() => new Date(), []);

  const isNewOrUpdated = useCallback(
    (question: ParentQuestion | ChildQuestion) => {
      const viewedAt = max(
        question.viewedAt,
        question.modifiedAt,
        question.answer.modifiedAt
      );
      const isNew = differenceInDays(now, question.firstViewedAt) < 1;
      const isUpdated = !isNew && differenceInDays(now, viewedAt) < 1;
      return isNew || isUpdated;
    },
    [now]
  );

  const visibleQuestions = useMemo(
    () =>
      allQuestions
        .filter((question) => {
          switch (status) {
            case "open":
              return !question.isClosed;
            case "closed":
              return question.isClosed;
            case "priority":
              return !question.isClosed && question.isPriority;
            case "new":
              return isNewOrUpdated(question);
            default:
              return true;
          }
        })
        .filter(categoryFilter.filterQuestion)
        .filter(projectFilter.filterQuestion)
        .filter(portfolioFilter.filterQuestion),
    [
      allQuestions,
      status,
      categoryFilter,
      projectFilter,
      portfolioFilter,
      isNewOrUpdated,
    ]
  );

  const sortedVisibleQuestions = useMemo(() => {
    const { field, direction } = ordering.ordering;

    switch (field) {
      case Field.NUMBER:
        return orderBy(
          visibleQuestions,
          [
            (q) => parseInt(q.number.split(".")[0]),
            (q) => parseInt(q.number.split(".")[1] ?? "0"),
          ],
          [direction, direction]
        );

      default:
        return visibleQuestions;
    }
  }, [visibleQuestions, ordering.ordering]);
  const dialogs = (
    <>
      <NewQuestionLogDialog
        subject={subject}
        visible={addLog}
        close={() => setAddLog(false)}
      />
      {routing.selectedLog?.id && (
        <UpdateQuestionLogDialog
          subject={subject}
          visible={editLog}
          close={() => setEditLog(false)}
          selectedLog={routing.selectedLog}
        />
      )}
    </>
  );
  // ColumnSelect context:
  const [selectedColumns, setSelectedColumns] = useState(defaultColumns);

  const toggleColumn = (column: keyof typeof defaultColumns) => {
    setSelectedColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const value = useMemo(
    () => ({ selectedColumns, toggleColumn }),
    [selectedColumns]
  );

  return (
    <QAndAContext.Provider value={context}>
      <OrderingContext.Provider value={ordering}>
        <CategoryFilterContext.Provider value={categoryFilter}>
          <ProjectFilterContext.Provider value={projectFilter}>
            <PortfolioFilterContext.Provider value={portfolioFilter}>
              <ColumnSelectContext.Provider value={value}>
                <>
                  <Title>Q&amp;A</Title>
                  <Controls>
                    <LastEdit saving={isSaving} questions={allQuestions} />
                    <QuestionLogDropdown
                      subject={subject}
                      selectedLog={routing.selectedLog}
                      setSelectedLog={routing.setSelectedLog}
                    />
                    {routing.selectedLog?.id && (
                      <NewQuestionButton
                        logId={routing.selectedLog?.id}
                        canEditQuestions={canEditQuestions}
                      />
                    )}
                    {routing.selectedLog?.id && (
                      <QuestionLogMenu
                        isQAndAAdmin={isQAndAAdmin}
                        selectedLog={routing.selectedLog}
                        addLog={() => setAddLog(true)}
                        editLog={() => setEditLog(true)}
                      />
                    )}
                  </Controls>
                  <FilterBar>
                    <StatusFilter
                      value={status}
                      questions={allQuestions}
                      onSelect={setStatus}
                    />
                    <FilterGroup>
                      <ColumnSelect />
                      <CategoryFilter />
                      {subject.__typename === "HoldingCompany" && (
                        <PortfolioFilter />
                      )}
                      {subject.__typename === "Portfolio" && <ProjectFilter />}
                    </FilterGroup>
                  </FilterBar>
                  <QuestionCategoriesProvider
                    logId={routing.selectedLog?.id || null}
                    canEditQuestions={canEditQuestions}
                  >
                    <QuestionPortfoliosProvider
                      subject={subject}
                      canEditQuestions={canEditQuestions}
                    >
                      <QuestionProjectsProvider
                        subject={subject}
                        canEditQuestions={canEditQuestions}
                      >
                        <QuestionsGrid
                          subject={subject}
                          gridRef={gridRef}
                          questions={sortedVisibleQuestions}
                          loading={questionsQuery.loading}
                          canEditQuestions={canEditQuestions}
                          canEditQuestionAnswers={canEditQuestionAnswers}
                        />
                      </QuestionProjectsProvider>
                    </QuestionPortfoliosProvider>
                  </QuestionCategoriesProvider>
                  {dialogs}
                </>
              </ColumnSelectContext.Provider>
            </PortfolioFilterContext.Provider>
          </ProjectFilterContext.Provider>
        </CategoryFilterContext.Provider>
      </OrderingContext.Provider>
    </QAndAContext.Provider>
  );
};

const Title = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
`;

const Controls = styled.div`
  ${centerContent};
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const FilterBar = styled.div`
  ${borderRadius("top")};
  ${bgColor.gray100()};
  ${border.gray300()};
  display: flex;
  padding: 0.375rem;
  border-bottom: none;
`;

// New styled component for spacing
const FilterGroup = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export default QAndA;
